'use client';

import {
  useRef,
  useImperativeHandle,
  forwardRef,
  MouseEventHandler,
  PropsWithChildren,
} from 'react';
import cn from 'classnames';

//* Style
import ContainerStyle from './style';

type ContainerProps = PropsWithChildren & {
  row?: boolean;
  isSection?: boolean;
  full?: boolean;
  className?: string;
  onMouseMove?: MouseEventHandler;
  maxWidth?: number;
};

const Container = forwardRef<HTMLDivElement, ContainerProps>(
  (
    { row, isSection, full, className, children, onMouseMove, maxWidth },
    ref
  ) => {
    //! Ref
    const contRef = useRef<HTMLDivElement>(null);

    //! Imperative Handle
    useImperativeHandle(ref, () => contRef.current!, []);

    return (
      <ContainerStyle
        ref={contRef}
        onMouseMove={onMouseMove}
        $maxWidth={maxWidth || 1360}
        className={cn('container', className, {
          'section-distance': isSection,
          full: full,
        })}
      >
        {row ? <div className="row">{children}</div> : children}
      </ContainerStyle>
    );
  }
);

Container.displayName = 'Container';

export default Container;
